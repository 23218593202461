<template>
  <Card id="plot" :expandable="false">
    <template #header-left>Speed restriction count by period</template>
    <template #header-right>
      <div class="loading">
        <div v-if="this.loading" class="loader" />
      </div>
    </template>
    <template #body>
      <div v-if="!this.loading || true" class="filters-row">
        <b>Filters</b>
        <label for="year">Year</label>
        <Dropdown id="year" v-model="this.year" :loading="this.filtersLoading" :options="this.filters.year.values"
          optionLabel="name" />
        <label for="region">Region</label>
        <Dropdown id="region" v-model="this.region" :loading="this.filters.region.loading"
          :options="this.filters.region.values" optionLabel="name" placeholder="All" />
        <label for="route">Route</label>
        <Dropdown id="route" v-model="this.route" :loading="this.filters.route.loading"
          :options="this.filters.route.values" optionLabel="name" placeholder="All" />
        <label for="type">SR Type</label>
        <Dropdown id="type" v-model="this.type" :loading="this.filters.type.loading" :options="this.filters.type.values"
          optionLabel="name" placeholder="All" />
        <label for="reason">Reason</label>
        <Dropdown id="reason" v-model="this.reason" :loading="this.filters.reason.loading"
          :options="this.filters.reason.values" optionLabel="name" placeholder="All" />
      </div>
      <div id="empty-plot" v-if="this.emptyPlot">No data for selected filters</div>
      <div ref="plotElement"></div>
    </template>
  </Card>
</template>

<script>
import axios from "axios";
import Card from "../common/Card.vue";
import Dropdown from "primevue/dropdown";
import Plotly from "plotly.js-dist";

export default {
  name: 'CountByNRPeriods',
  components: {
    Card,
    Dropdown
  },
  data() {
    return {
      year: { name: "2022" },
      region: { name: null },
      route: { name: null },
      type: { name: null },
      reason: { name: null },

      filters: {
        year: {
          values: [
            { name: "2019" },
            { name: "2020" },
            { name: "2021" },
            { name: "2022" },
            { name: "2023" },
            { name: "2024" },
            { name: "2025" },
            { name: "2026" }
          ]
        },
        region: {
          loading: true,
          values: []
        },
        route: {
          loading: true,
          values: []
        },
        type: {
          loading: true,
          values: []
        },
        reason: {
          loading: true,
          values: []
        }
      },

      loading: true,
      emptyPlot: false,
      plotConfig: {
        config: { responsive: true, displaylogo: false }
      }
    }
  },
  mounted() {
    this.getFilterValues("region", this.filters.region);
    this.getFilterValues("route", this.filters.route);
    this.getFilterValues("type", this.filters.type);
    this.getFilterValues("reason", this.filters.reason);
    this.getPlotData();
  },
  computed: {
    filtersLoading: function () {
      return this.filters.region.loading ||
        this.filters.route.loading ||
        this.filters.type.loading ||
        this.filters.reason.loading;
    }
  },
  watch: {
    year: function () { this.getPlotData(); },
    region: function () { this.getPlotData(); },
    route: function () { this.getPlotData(); },
    type: function () { this.getPlotData(); },
    reason: function () { this.getPlotData(); },
    loading: function () {
      this.togglePlotElement();
    }
  },
  methods: {
    togglePlotElement() {
      // Hide the plot element if we're actively loading or the response data
      // was empty.
      let hide = this.loading || this.emptyPlot;

      if (hide) {
        this.$refs.plotElement.style.display = "none";
      }
      else {
        this.$refs.plotElement.style.display = null;
      }
    },
    getFilterValues(filterName, filter) {
      filter.loading = true;

      axios({
        url: `${process.env.VUE_APP_API_SERVER}/management/get-${filterName}-filter-options`,
        method: "GET",
        headers: {
          "accept": "application/json",
          "Authorization": `Bearer ${this.$store.state.authenticator.token}`
        }
      })
        .then(response => {
          filter.values = response.data.map(f => ({ name: f }));
          filter.values.splice(0, 0, { name: "All" });

          filter.loading = false;
        })
    },
    getPlotData() {
      this.loading = true;
      this.emptyPlot = false;

      axios({
        url: `${process.env.VUE_APP_API_SERVER}/management/get-sr-count-plot-json`,
        params: {
          year: this.year.name,
          region: this.region.name !== 'All' ? this.region.name : null,
          route: this.route.name !== 'All' ? this.route.name : null,
          type: this.type.name !== 'All' ? this.type.name : null,
          reason: this.reason.name !== 'All' ? this.reason.name : null
        },
        method: "GET",
        headers: {
          "accept": "application/json",
          "Authorization": `Bearer ${this.$store.state.authenticator.token}`
        }
      })
        .then(response => {
          if (Object.keys(response.data).length === 0) {
            this.emptyPlot = true;
          }
          else {
            let plotData = {
              ...response.data, ...this.plotConfig
            };

            Plotly.react(this.$refs.plotElement, plotData);
            this.emptyPlot = false;
          }

          this.loading = false;
        })
    }
  }
};
</script>

<style scoped>
#plot {
  width: 100%;
}

div#empty-plot {
  padding: 10px;
  margin: 10px;
}

/* 
    Filters row styling
*/
.filters-row {
  font-size: 1rem;
  margin-left: 1rem;
  padding: 1rem 0 1rem 0.5rem;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--colour-2);
}

.filters-row>b {
  margin-right: 2rem;
}

.filters-row>.filter {
  margin-right: 1rem;
}

.loader {
  padding: 5px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
